<script setup lang="ts">
  import SidebarLink from '~/components/UI/Utilities/SidebarLink.vue';
  import SidebarSection from '~/components/UI/Utilities/SidebarSection.vue';

  const { isExpenseEnabled } = useAuth();
</script>

<template>
  <div class="flex w-full h-[calc(100vh-55px)]">
    <div class="flex flex-col w-[264px] p-2 border-r border-zinc-200 overflow-y-auto fixed bottom-0 top-[55px]">
      <SidebarLink :to="'/'" label="Home" />
      <SidebarLink :to="'/organization-analysis'" label="Organization Analysis" />
      <SidebarLink :to="'/donor-analysis'" label="Donor Analysis" />
      <SidebarLink :to="'/donation-analysis'" label="Donation Analysis" />
      <SidebarLink :to="'/pledges-analysis'" label="Pledges Analysis" />
      <SidebarLink :to="'/grant-analysis'" label="Grant Analysis" />
      <SidebarLink :to="'/grant-utilization-analysis'" label="Grant Utilization Analysis" />
      <SidebarLink v-if="isExpenseEnabled" :to="'/expense-analysis'" label="Expense Analysis" />
      <SidebarLink :to="'/payee-analysis'" label="Payee Analysis" />
      <SidebarLink v-if="isExpenseEnabled" :to="'/budget-analysis'" label="Budget Analysis" />
      <SidebarLink :to="'/purpose-analysis'" label="Purpose Analysis" />

      <SidebarSection title="Reports">
        <SidebarLink :to="'/grant-utilization-report'" label="Grant Utilization Report" />
        <SidebarLink :to="'/fund-balance-report'" label="Fund Balance Report" />
        <SidebarLink :to="'/all-grants-data-export'" label="All Grants Data Export" />
        <SidebarLink :to="'/user-vs-grants'" label="User vs Grants" />
        <SidebarLink :to="'/all-budget-data-export'" label="All Budget Data Export" />
        <SidebarLink v-if="isExpenseEnabled" :to="'/expenses-report'" label="Expenses Report" />
        <SidebarLink v-if="isExpenseEnabled" :to="'/expense-attachment-report'" label="Expense Attachment Report" />
        <SidebarLink :to="'/payment-report'" label="Payment Report" />
        <SidebarLink :to="'/donation-report'" label="Donation Report" />
        <SidebarLink v-if="isExpenseEnabled" :to="'/payee-expense-report'" label="Payee Expense Report" />
      </SidebarSection>
    </div>
    <div class="ml-[264px] w-[calc(100%-264px)]">
      <slot />
    </div>
  </div>
</template>
